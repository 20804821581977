import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetDashboardStatsDocument = gql`
  query GetDashboardStats($invoicesFilters: InvoicesQueryFiltersInput) {
    dashboardStats {
      invoicesAwaitingApprovalCount
      invoicesFlaggedCount
      purchaseOrdersNotSentCount
      requisitionsAwaitingApprovalCount
    }
    invoices(filters: $invoicesFilters) {
      totalCount
    }
  }
` as unknown as DocumentNode<Types.GetDashboardStatsQuery, Types.GetDashboardStatsQueryVariables>
