import { useQuery } from '@apollo/client'

import { GetDashboardStatsDocument } from '@/graphql/purchase-plus/generated/getDashboardStats.generated'
import { InvoiceStatusEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export function useGetDashboardStats() {
  const { user, currentOrganisation } = useSessionUser()
  const { data, error, loading } = useQuery(GetDashboardStatsDocument, {
    variables: {
      invoicesFilters: {
        state: InvoiceStatusEnum.Flagged,
        awaitingWorkflowApprovalForUserId: user?.id,
      },
    },
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
  })

  return {
    dashboardStats: {
      ...data?.dashboardStats,
      invoicesAwaitingApprovalCount: currentOrganisation?.featureFlags?.newApprovalWorkflows
        ? data?.invoices?.totalCount
        : data?.dashboardStats?.invoicesAwaitingApprovalCount,
    },
    error,
    loading,
  }
}
