import { useEffect, useState } from 'react'
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Account, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks/useCurrentPurchaser'
import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import useGetAccounts from '@/modules/shared/hooks/useGetAccounts'
import { useGetDepartments } from '@/modules/shared/hooks/useGetDepartments'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface DeptAndAccountProps {
  defaultDepartmentValue?: Pick<Purchaser, 'id' | 'name'>
  defaultAccountValue?: Pick<Account, 'id' | 'accountName'>
  showExplanation?: boolean
  disabled?: boolean
  title?: string
  description?: string
  required?: boolean
  className?: string
  onlyDepartments?: boolean
  onlyAccounts?: boolean
  showAsRow?: boolean
  departmentTooltip?: string
  accountTooltip?: string
  purchaserId?: number
  saveAsObject?: boolean
  floating?: boolean
}

export default function DeptAndAccount({
  defaultAccountValue,
  defaultDepartmentValue,
  showExplanation = true,
  disabled,
  title,
  description,
  required = true,
  className,
  onlyDepartments,
  onlyAccounts,
  showAsRow,
  departmentTooltip,
  accountTooltip,
  purchaserId,
  saveAsObject = false,
  floating = false,
}: DeptAndAccountProps) {
  const { t } = useTranslation()
  const {
    resetField,
    getValues,
    control,
    formState: { errors },
  } = useFormContext()
  const [hasDefault, setHasDefault] = useState(false)
  const [resetAccount, setResetAccount] = useState(0)
  const { currentPurchaser } = useCurrentPurchaser()

  const { departments, loadingStates: departmentLoadingStates } = useGetDepartments({ purchaserId })
  const {
    accounts,
    refetch,
    loadingStates: accountLoadingStates,
  } = useGetAccounts({ departmentId: Number(defaultDepartmentValue?.id) })

  const onSelectedDepartment = (
    e: Pick<Purchaser, 'id' | 'name'>,
    field: ControllerRenderProps<FieldValues, 'departmentId' | 'department'>
  ) => {
    field.onChange(saveAsObject ? { id: e.id, name: e.name } : e.id)
    if (!hasDefault) {
      resetField(saveAsObject ? 'account' : 'accountId', { defaultValue: null })
      refetch({ departmentId: getValues(saveAsObject ? 'department.id' : 'departmentId') || undefined })
      setResetAccount(e.id)
    } else {
      setHasDefault(false)
    }
  }

  useEffect(() => {
    if (defaultDepartmentValue) {
      refetch({ departmentId: defaultDepartmentValue?.id })
      setHasDefault(true)
    }
  }, [defaultDepartmentValue?.id])

  useEffect(() => {
    if (onlyAccounts) {
      refetch({ departmentId: currentPurchaser?.id })
    }
  }, [onlyAccounts])

  return (
    <section
      className={classNames(className, { 'mt-8': showExplanation && !className && !showAsRow })}
      data-testid="department-and-account"
    >
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div
        className={classNames('w-full', {
          'mt-3': showExplanation && !showAsRow,
          'flex gap-x-4': showAsRow,
          'space-y-3': !showAsRow,
        })}
      >
        {!onlyAccounts && (
          <div className={classNames({ 'w-1/2': showAsRow })}>
            {showAsRow && (
              <p className="mb-1.5 flex gap-x-1.5 font-bold">
                {t('general.department', 'Department')}
                <Tooltip content={departmentTooltip || ''}>
                  <QuestionMarkIcon className="size-4" />
                </Tooltip>
              </p>
            )}
            <Controller
              control={control}
              name={saveAsObject ? 'department' : 'departmentId'}
              rules={{ required }}
              render={({ field }) => (
                <ComboboxClient
                  testId="department"
                  defaultValue={defaultDepartmentValue}
                  loading={departmentLoadingStates.loading}
                  placeholder={t('general.selectADepartmentDots', 'Select a Department...')}
                  keyFilter="name"
                  keyExtractor={(e) => String(e.id)}
                  items={departments?.map((dept) => ({ id: dept.id, name: dept.name })) || []}
                  onSelected={(e) => onSelectedDepartment(e, field)}
                  hasError={Boolean(saveAsObject ? errors.department : errors.departmentId)}
                  disabled={disabled}
                  floating={floating}
                />
              )}
            />
          </div>
        )}
        {!onlyDepartments && (
          <div className={classNames({ 'w-1/2': showAsRow })}>
            {showAsRow && (
              <p className="mb-1.5 flex gap-x-1.5 font-bold">
                {t('general.accountCode', 'Account Code')}
                <Tooltip content={accountTooltip || ''}>
                  <QuestionMarkIcon className="size-4" />
                </Tooltip>
              </p>
            )}
            <Controller
              control={control}
              name={saveAsObject ? 'account' : 'accountId'}
              rules={{ required }}
              render={({ field }) => (
                <ComboboxClient
                  testId="account"
                  defaultValue={defaultAccountValue}
                  loading={accountLoadingStates.setVariablesLoading}
                  disabled={
                    (disabled || (saveAsObject ? !getValues('department.id') : !getValues('departmentId'))) &&
                    !onlyAccounts
                  }
                  placeholder={t('createRequisition.deptAndAccount.placeholder.account', 'Select an Account Code...')}
                  keyFilter="accountName"
                  keyExtractor={(e) => String(e.id)}
                  items={accounts as Account[]}
                  onSelected={(e) => field.onChange(saveAsObject ? { id: e.id, accountName: e.accountName } : e.id)}
                  hasError={Boolean(saveAsObject ? errors.account : errors.accountId)}
                  errorMessage={t(
                    'createRequisition.deptAndAccount.required.account',
                    'You must select a Department and an Account Code.'
                  )}
                  resetSelectItem={resetAccount}
                  floating={floating}
                />
              )}
            />
          </div>
        )}
      </div>
    </section>
  )
}
